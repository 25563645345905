
class ProjectInfo
{
    name = "";
    description = "";
    videoUrl = "";
    imageUrls = [];
    usePortrait = false;
    iOSStoreUrl = "";
    playStoreUrl = "";

    constructor(name, description, video, images, usePortrait, iOSStoreUrl, playStoreUrl) {
        this.name = name;
        this.description = description;
        this.videoUrl = video;
        this.imageUrls = images;
        this.usePortrait = usePortrait;
        this.iOSStoreUrl = iOSStoreUrl;
        this.playStoreUrl = playStoreUrl;
    }

}

export default ProjectInfo