import React from "react";
import linkedin_icon from "./assets/img/linkedin-icon.png";
import Portfolio from './Portfolio/Portfolio';
// import 'react-tabs/style/react-tabs.css';

class HomePage extends React.Component {

    render() {
        return (
        <div 
        style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            margin: 20
        }}>
            <span style={{fontSize: 80}} class="myname">Segun Sojobi</span>
            <span style={{fontSize: 24}}>Software Engineer</span>
            <span><b>Email:</b> sojobiolusegun@gmail.com</span>
            <a href="https://www.linkedin.com/in/segunsojobi" target="_blank">
                <img src={linkedin_icon}
                    style={{width:16, height:16}}/>
            </a>

            <p style={{fontSize:24, marginTop: 50, maxWidth: 600}}>
                I am a Game Engineer with an intense dedication to developing immersive and engaging gaming experiences. 
                With a strong foundation and proficiency in C# and Unity Engine, I have a track record of 
                successfully delivering high-quality games from concept to completion. 
                In my 7 years of software engineering work experience, I had also worked on iOS, Android 
                and backend applications, utilizing Swift, Java, NodeJS, Golang, Firebase, MySQL, 
                Flutter, React and other tools/frameworks.
            </p>

            <Portfolio/>
        </div>);
    }
}

export default HomePage;
