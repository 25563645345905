import React from "react";

//game screenshots
import jarusJourneyImg_1 from "../assets/img/jarus-journey-1.webp";
import jarusJourneyImg_2 from "../assets/img/jarus-journey-2.webp";
import jarusJourneyImg_3 from "../assets/img/jarus-journey-3.webp";
import sniperImg_1 from "../assets/img/Sniper-3d-1.webp";
import sniperImg_2 from "../assets/img/Sniper-3d-2.webp";
import sniperImg_3 from "../assets/img/Sniper-3d-3.webp";
import tennisClashImg_1 from "../assets/img/Tennis-Clash-1.webp";
import tennisClashImg_2 from "../assets/img/Tennis-Clash-2.webp";
import tennisClashImg_3 from "../assets/img/Tennis-Clash-3.webp";
import warMachinesImg_1 from "../assets/img/War-Machines-1.webp";
import warMachinesImg_2 from "../assets/img/War-Machines-2.webp";

//ios app screenshots
import databeaverImg_1 from "../assets/img/databeaver-1.webp";
import databeaverImg_2 from "../assets/img/databeaver-2.webp";
import databeaverImg_3 from "../assets/img/databeaver-3.webp";
import eyowoImg_1 from "../assets/img/eyowo-1.webp";
import eyowoImg_2 from "../assets/img/eyowo-2.webp";
import mtndmlImg_1 from "../assets/img/mtndml-1.jpg";
import mtndmlImg_2 from "../assets/img/mtndml-2.jpg";
import mtndmlImg_3 from "../assets/img/mtndml-3.jpg";
import mtndmlImg_4 from "../assets/img/mtndml-4.jpg";

import ProjectInfoComponent from "./ProjectInfoComponent";
import ProjectInfo from "./ProjectInfo";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class Portfolio extends React.Component {
    
    render() {
        var screenshotsStyle = {
            backgroundColor: "red"
        };

        var gameProjects = [
            new ProjectInfo("Sniper3D", 
            "A first-person shooter game.", 
            process.env.PUBLIC_URL+"/vid/Sniper-3d-vid.mp4",
            [sniperImg_1, sniperImg_2, sniperImg_3], false, 
            "https://apps.apple.com/us/app/sniper-3d-gun-shooting-games/id930574573",
            "https://play.google.com/store/apps/details?id=com.fungames.sniper3d"),

            new ProjectInfo("Tennis Clash", 
            "An online, multiplayer tennis game with amazing 3D graphics and intuitive controls where you swipe to score.", 
            process.env.PUBLIC_URL+"/vid/Tennis-Clash-vid.mp4", 
            [tennisClashImg_1, tennisClashImg_2, tennisClashImg_3], true,
            "https://apps.apple.com/us/app/tennis-clash-multiplayer-games/id1346179411",
            "https://play.google.com/store/apps/details?id=com.tfgco.games.sports.free.tennis.clash"),

            new ProjectInfo("War Machines", 
            "A fast and explosive tank battle game.", 
            process.env.PUBLIC_URL+"/vid/War-Machines-vid.mp4", 
            [warMachinesImg_1, warMachinesImg_2], false,
            "https://apps.apple.com/us/app/war-machines-tanks-battle-game/id1058528141",
            "https://play.google.com/store/apps/details?id=com.fungames.battletanksbeta"),

            new ProjectInfo("Jaru's Journey", 
            "A fun, 2D platformer, epic adventure game, inspired by African Culture, Art and Storytelling.", 
            "", 
            [jarusJourneyImg_1, jarusJourneyImg_2, jarusJourneyImg_3], true,
            "https://apps.apple.com/bs/app/jarus-journey/id1586029793",
            "https://play.google.com/store/apps/details?id=com.griotstudios.jarusjourney")
        ]

        var iOSProjects = [
            new ProjectInfo("Eyowo", 
            "Eyowo is a CBN-regulated bank that unifies all the financial tools you need to pay, receive, budget and save money in one place.", 
            "", 
            [eyowoImg_1, eyowoImg_2], true,
            "https://apps.apple.com/us/app/eyowo/id1353757720",
            ""),
            new ProjectInfo("Data Beaver", 
            "A tool that enables businesses and organisations to make insightful and quantitative decisions through the use of data captured across several data points.", 
            "", 
            [databeaverImg_1, databeaverImg_2, databeaverImg_3], true,
            "https://apps.apple.com/app/data-beaver/id1464316127", 
            ""),
            new ProjectInfo("MTN Digital Mobile Library", 
            "A digital library for people (mostly students) to acquire new skills and learn to execute tasks that would add to their skillset.", 
            "", 
            [mtndmlImg_1, mtndmlImg_2, mtndmlImg_3, mtndmlImg_4], true,
            "https://apps.apple.com/us/app/mtn-digital-mobile-library/id1354707858?ls=1",
            ""),
        ]


        return (
        <div >
            <h3>Portfolio</h3>
            <Tabs>
                <TabList>
                    <Tab>Games</Tab>
                    <Tab>iOS Apps</Tab>
                </TabList>
                <TabPanel>
                {
                gameProjects.map((data, i)=>{
                    return <ProjectInfoComponent key={i} project={data} />
                })
                }
                </TabPanel>
                <TabPanel>
                {
                iOSProjects.map((data, i)=>{
                    return <ProjectInfoComponent key={i} project={data} />
                })
                }
                </TabPanel>
            </Tabs>
        
        </div>
        )
    }
}

export default Portfolio;
