import React from "react";
import appleIcon from "../assets/img/get-on-app-store.jpg";
import playStoreIcon from "../assets/img/get-on-google-play.jpg";

class ProjectInfoComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var projectInfo = this.props.project;
        var storeIconWidth = 97;
        var storeIconHeight = 32;

        return (
            <div style={{marginTop: 50}}>
            <span><b>{projectInfo.name}</b></span>
            <br/><br/><span>{projectInfo.description}</span><br/>

            <div className="appScreenshotsRow" style={{
                display: "flex",
                flexDirection: "row",
                overflowX: "auto",
                gap: 10,
                marginTop: 10
            }}>
                {
                    projectInfo.videoUrl && 
                <video className={projectInfo.usePortrait ? "iOSAppStoreVideoPortrait" : "iOSAppStoreVideoLandscape"} controls>
                    <source src={projectInfo.videoUrl} type="video/mp4"/>
                    Your browser does not support HTML video.
                </video>
                }
                {
                    projectInfo.imageUrls.map((item, i)=>{
                        return <img 
                        key={i} 
                        className={projectInfo.usePortrait ? "iOSAppStoreScreenshotPortrait" : "iOSAppStoreScreenshotLandscape"} 
                        src={item}/>
                    })
                }
            </div>
            
            {
            projectInfo.iOSStoreUrl &&
                <a href={projectInfo.iOSStoreUrl} target="_blank">
                <img src={appleIcon}
                    style={{width:storeIconWidth, height:storeIconHeight, marginTop:10}}/>
            </a>
            }

            {
            projectInfo.playStoreUrl &&
                <a href={projectInfo.playStoreUrl} target="_blank">
                <img src={playStoreIcon}
                    style={{width:storeIconWidth, height:storeIconHeight, marginLeft: 10}}/>
            </a>
            }
        </div>
        )
    }
}

export default ProjectInfoComponent;